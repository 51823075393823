import {
  getAlarmNotiCountAPI,
  getAlarmListAPI,
  getAlarmDetailInfoAPI,
  updateAlarmInfoAPI,
  insertAlarmProcAPI,
  getNoticeListAPI,
  updateNoticeReadAPI,
  getBannerListAPI,
  getSettingInfoAPI,
  updateSettingInfoAPI,
  uploadAvatarImageAPI,
  getLanguageListAPI,
  getMyRoomListAPI,
  updateSettingRoomAlarmAllAPI,
  updateSettingLanguageAPI,
  getMyGroupAccountAPI,
  getMyGroupDashboardAPI,
  updateMyGroupDashboardAPI,
  getMyGroupListAPI,
  updateMyGroupListAPI,
  cancelMyGroupListAPI,
  getMyGroupJuboListAPI,
  getMyGroupSermonListAPI,
  getFellowListAPI,
  getFellowDetailAPI,
  cancelMembershipAPI,
  checkGroupIdAPI,
  registGroupInfoAPI,
  getYoutubeChannelVideoListAPI,
  saveYoutubeChannelVideoListAPI,
  deleteYoutubeChannelVideoListAPI,
  getVimeoVideoInfoAPI,
  get365AuthKeyAPI,
} from "~/store/api/more";
import dayjs from "dayjs";
import _ from "lodash";
import { isEmpty, decodeChar } from "~/utils/utils";

export const useMoreStore = defineStore("more", {
  state: () => ({
    orderModalOpen: false,
    orderModalClose: false,
    drag: false,
    myGroupList: [],
    dashboardList: [],

    alarmNotiCount: {
      alarm: 0,
      noti: 0,
    },

    alarmList: [],
    alarmPaging: {
      // lastIdx: 0,
      isEnd: false,
    },
    alarmPayload: {
      startNum: 0,
      /* endNum: 1,
      mssgChkYn: "N", */
    },

    noticeList: [],
    noticePaging: {
      // lastIdx: 0,
      isEnd: false,
    },
    noticePayload: {
      startNum: 0,
    },

    sermonGrpName: "",
    sermonList: [],
    sermonPaging: {
      // lastIdx: 0,
      isEnd: false,
    },
    sermonPayload: {
      grpCd: "",
      startNum: 0,
      endNum: 10,
    },

    youtubeChannelVideoList: [],

    bannerList: [],

    langList: [],

    settingInfo: {
      loginId: "", // 성명
      memName: "", // 성명
      nickName: "", // 닉네임
      memContact: "", // 휴대폰번호
      memSex: "M", // 성별
      memBirthday: "", // 생년월일(19990101)
      memEmail: "", // 이메일
      profilePhoto: "", // 프로필사진
      chrchName: "", // 출석교회
      selectedLangCd: "",
      selectedLangName: "",
      pushAlarmRcv: true,
      alarmDate: "",
      siteAlarmYn: false,
      roomAlarmYn: false,
      roomAlarmCnt: 0,
    },
  }),
  actions: {
    // 새 알림, 공지 개수
    async getAlarmNotiCount() {
      try {
        const { statusText, data: res = {} } = await getAlarmNotiCountAPI();
        if (statusText !== "OK" || res?.alarm === undefined || res?.noti === undefined) throw Error;

        // this.alarmNotiCount.alarm = 3;
        // this.alarmNotiCount.noti = 2;

        this.alarmNotiCount.alarm = res.alarm;
        this.alarmNotiCount.noti = res.noti;
      } catch (err) {
        console.error(`getAlarmNotiCount err : `, err);
      }
    },
    stringActor(string) {
      return string.replace(/\"/g, "");
    },

    // 알림목록
    async getAlarmList() {
      try {
        if (isEmpty(this.alarmList) || this.alarmList.length === 0) {
          this.alarmPayload.startNum = 0;
          // this.alarmPaging.lastIdx = 0;
          this.alarmPaging.isEnd = false;
        }

        if (this.alarmPaging.isEnd === false) {
          if (!isEmpty(this.alarmList) && this.alarmList.length > 0) this.alarmPayload.startNum += 20;

          const { data: res = {} } = await getAlarmListAPI(this.alarmPayload);
          if (res?.result === undefined) throw Error;

          if (res.result.length < 20) this.alarmPaging.isEnd = true;

          this.alarmList = [
            ...this.alarmList,
            ...res.result.map((itm) => {
              const mssgTitle = this.stringActor(itm.mssgTitle);
              // const mssgCnts = !isEmpty(itm.mssgCnts) ? this.stringActor(itm.mssgCnts) : this.stringActor(itm.mssgTitle);
              const mssgWriteDate = dayjs(itm.mssgWriteDate).format("YY/MM/DD - hh:mm");
              const mssgChkYn = itm.mssgChkYn === "Y";
              const mssgRspYn = itm.mssgRspYn === "Y";

              let fdbckNotiCd = {},
                fdbckConfirm = false; // feedback(승인/거절) 여부

              if (!isEmpty(itm.fdbckNotiCd) && !mssgRspYn) {
                fdbckNotiCd = itm.fdbckNotiCd.split(",").reduce(function (obj, str, index) {
                  let strParts = str.split(":");
                  if (strParts[0] && strParts[1]) obj[strParts[0].trim()] = strParts[1].trim();
                  return obj;
                }, {});

                fdbckConfirm = true;
              }

              return {
                ...itm,
                notiMssgCd: itm.notiMssgCd,
                sendMemCd: itm.sendMemCd,
                rcvMemCd: itm.rcvMemCd,
                siteLangCd: itm.siteLangCd,
                roomCd: itm.roomCd,
                grpCd: itm.grpCd,
                diaryCd: itm.diaryCd,
                diaryRcmdCd: itm.diaryRcmdCd,
                rcmdTypeCd: itm.rcmdTypeCd,
                rcmdRsn: itm.rcmdRsn,
                mssgReplyCnts: itm.mssgReplyCnts,
                fdbckNotiCd: fdbckNotiCd,
                fdbckConfirm: fdbckConfirm,
                mssgCnts: "",
                mssgWriteDate: mssgWriteDate,
                mssgChkYn: mssgChkYn,
                mssgTitle: mssgTitle,
              };
            }),
          ];

          // this.alarmPaging.lastIdx++;

          // return res;
        }
      } catch (err) {
        console.error(`getAlarmList err : `, err);
      }
    },

    // 알림상세
    async getAlarmDetailInfo(payload) {
      try {
        const { statusText, data: res = {} } = await getAlarmDetailInfoAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`getAlarmDetailInfo err : `, err);
      }
    },

    // 알림 읽음처리
    async updateAlarmInfo(payload) {
      try {
        const { statusText, data: res = {} } = await updateAlarmInfoAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateAlarmInfo err : `, err);
      }
    },

    // 알림 승인/거절 처리
    async insertAlarmProc(payload) {
      try {
        const { statusText, data: res = {} } = await insertAlarmProcAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`insertAlarmProc err : `, err);
      }
    },

    // 공지사항
    async getNoticeList() {
      try {
        if (isEmpty(this.noticeList) || this.noticeList.length === 0) {
          this.noticePayload.startNum = 0;
          // this.noticePaging.lastIdx = 0;
          this.noticePaging.isEnd = false;
        }

        if (this.noticePaging.isEnd === false) {
          if (!isEmpty(this.noticeList) && this.noticeList.length > 0) this.noticePayload.startNum += 20;

          const stripPattern = /(<([^>]+)>)/gi;
          const hrefPattern = /<a[^>]*href=['"]([^'"]*)['"][^>]*>/;
          const srcPattern = /<img[^>]*src=['"](\/\/[^'"]+)['"][^>]*>/;
          const { data: res = {} } = await getNoticeListAPI(this.noticePayload);
          if (res?.result === undefined) throw Error;

          if (res.result.length < 20) this.noticePaging.isEnd = true;

          this.noticeList = [
            ...this.noticeList,
            ...res.result.map((itm) => {
              // const postCnts = itm.postCnts.replace(stripPattern, "");

              // const hrefMatch = itm.postCnts.match(hrefPattern);
              // const srcMatch = itm.postCnts.match(srcPattern);
              // const link = hrefMatch?.[1] ? hrefMatch[1] : "https://jwj.kr";
              // const image = srcMatch?.[1] ? "https:" + srcMatch[1] : "";

              // if(image != "") {
              //   postContents = postContents + "<a href='" + link + "' target='_blank'>";
              //   postContents = postContents + "<img src='" + image + "'>";
              //   postContents = postContents + "</a>";
              // }

              const postDate = dayjs(itm.regLDate).format("YY/MM/DD");
              const isNew = itm.postChkYn == "N" ? true : false;

              let bnrImg = "",
                bnrLnk = "";

              if (itm.bnrImg != "") {
                bnrImg = "https://jwj.kr/upload" + itm.bnrImgSavePath + itm.bnrImg;
                bnrLnk = itm.bnrLnk;
              }

              return {
                // ...itm,
                postCd: itm.postCd,
                postTitle: itm.postTitle,
                postCnts: itm.postCnts,
                postDate: postDate,
                isNew: isNew,
                bnrImg: bnrImg,
                bnrLnk: bnrLnk,
              };
            }),
          ];

          // this.noticePaging.lastIdx++;

          // return res;
        }
      } catch (err) {
        console.error(`getNoticeList err : `, err);
      }
    },

    // 공지사항 읽음 처리
    async updateNoticeRead(payload) {
      try {
        const { statusText, data: res = {} } = await updateNoticeReadAPI(payload);
        if (statusText == "OK" && !res.result) payload.gubun = "";

        return res;
      } catch (err) {
        console.error(`updateNoticeRead err : `, err);
      }
    },

    // 배너
    async getBannerList() {
      try {
        const { statusText, data: res = {} } = await getBannerListAPI();
        if (statusText !== "OK" || res?.bannerList === undefined) throw Error;

        this.bannerList = [
          ...res.bannerList.map((itm) => ({
            bnrImg: itm.bnrImg,
            bnrLnk: itm.bnrLnk,
            bnrTarget: itm.bnrTarget,
            postTitle: itm.postTitle,
          })),
        ];
      } catch (err) {
        console.error(`getBannerList err : `, err);
      }
    },

    // 설정 정보
    async getSettingInfo() {
      try {
        const { statusText, data: res = {} } = await getSettingInfoAPI();
        if (statusText !== "OK" || res?.user === undefined || res?.roomAlarmCnt === undefined) throw Error;
        const { data: resLang = {} } = await getLanguageListAPI();
        const { data: resRoomList = {} } = await getMyRoomListAPI(); // 내 나눔방 목록

        let loginId = res.user.loginId,
          memCd = res.user.memCd,
          memName = res.user.memName,
          nickName = res.user.nickName,
          memSex = res.user.memSex,
          memBirthday = res.user.memBirthday,
          memEmail = res.user.memEmail,
          phoneNumber = res.user.memContact,
          chrchCd = res.user.chrchCd,
          chrchName = res.user.chrchName,
          dutyTypeCd = res.user.dutyTypeCd,
          profilePhoto = !isEmpty(res.user.profilePhoto) ? "https://jwj.kr/upload/member/" + res.user.profilePhoto : "",
          selectedLangCd = res.user.selectedLangCd, // 언어코드
          pushAlarmRcv = res.user.pushAlarmRcv === "Y", // 위지엠 공지 알림 여부
          alarmDate = res.user.alarmDate, // 일기쓰기시간
          siteAlarmYn = res.user.siteAlarmYn === "Y", // 일기쓰기시간 알림 여부
          roomAlarmYn = Boolean(res.roomAlarmCnt), // 나눔방 알림 설정 여부
          roomCnt = resRoomList.result.length; // 가입된 나눔방수

        if (phoneNumber.match(/^\d{11}$/))
          phoneNumber = phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 7) + "-" + phoneNumber.substring(7);

        // 언어명
        let selectedLangName = "";
        resLang.langList.some((list) => {
          if (list.commonCd == selectedLangCd) {
            selectedLangName = list.commonCdName;
            return true;
          }
        });

        this.settingInfo = {
          loginId, // 아이디
          memCd, // code
          memName, // 성명
          nickName, // 별명
          memSex, // 성별
          memBirthday, // 생년월일
          memEmail, // 이메일
          phoneNumber, // 휴대폰번호
          profilePhoto, // 프로필사진
          chrchCd, // 출석교회
          chrchName, // 출석교회
          dutyTypeCd, // 직분
          selectedLangCd, // 언어코드
          selectedLangName, // 언어
          pushAlarmRcv,
          alarmDate,
          siteAlarmYn,
          roomAlarmYn,
          roomCnt,
        };
      } catch (err) {
        console.error(`getSettingInfo err : `, err);
      }
    },

    // 설정 정보 저장
    async updateSettingInfo(payload) {
      try {
        const savePayload = {
          memCd: payload.memCd,
        };
        if (payload.myPledge) savePayload.myPledge = payload.myPledge;

        if (!isEmpty(payload.alarmDate)) savePayload.alarmDate = payload.alarmDate;
        if (!isEmpty(payload.siteAlarmYn)) savePayload.siteAlarmYn = payload.siteAlarmYn ? "Y" : "N";
        if (!isEmpty(payload.pushAlarmRcv)) savePayload.pushAlarmRcv = payload.pushAlarmRcv ? "Y" : "N";

        const { statusText, data: res = {} } = await updateSettingInfoAPI(savePayload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result; // 0 or 1
      } catch (err) {
        console.error(`updateSettingInfo err : `, err);
      }
    },

    // 프로필 이미지 저장
    async uploadAvatarImage(payload) {
      try {
        const { statusText, data: res = {} } = await uploadAvatarImageAPI({ ...payload });
        if (statusText !== "OK" || res === undefined) throw Error;

        if (res.result) res.profilePhoto = "https://jwj.kr/upload/member/" + res.profilePhoto;

        return res;
      } catch (err) {
        console.error(`uploadAvatarImage err : `, err);
      }
    },

    // 나눔방 알람 설정 저장
    async updateSettingRoomAlarmAll(payload) {
      try {
        const isAlarmRcv = payload.alarmRcvYn,
          savePayload = {
            alarmRcvYn: isAlarmRcv ? "Y" : "N",
          };

        const { statusText, data: res = {} } = await updateSettingRoomAlarmAllAPI(savePayload);
        if (statusText == "OK" && !res.result) payload.alarmRcvYn = !isAlarmRcv;

        return res;
      } catch (err) {
        console.error(`updateSettingRoomAlarmAll err : `, err);
      }
    },

    // 언어 목록 정보
    async getLanguageList() {
      try {
        const { statusText, data: res = {} } = await getLanguageListAPI();
        if (statusText !== "OK" || res?.langList === undefined) throw Error;

        this.langList = res.langList;
      } catch (err) {
        console.error(`getLanguageList err : `, err);
      }
    },

    // 언어 설정 저장
    async updateSettingLanguage(payload) {
      try {
        const { statusText, data: res = {} } = await updateSettingLanguageAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateSettingLanguage err : `, err);
      }
    },

    // 온라인헌금 계좌 정보
    async getMyGroupAccount(payload) {
      try {
        const { statusText, data: res = {} } = await getMyGroupAccountAPI(payload);
        // if (statusText !== "OK" || res?.result === undefined) throw Error;
        if (statusText !== "OK") throw Error;

        return res;
      } catch (err) {
        console.error(`getMyGroupAccount err : `, err);
      }
    },

    // 주보
    async getMyGroupJuboList(payload) {
      try {
        const { statusText, data: res = {} } = await getMyGroupJuboListAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res;
      } catch (err) {
        console.error(`getMyGroupJuboList err : `, err);
      }
    },

    // 설교
    async getMyGroupSermonList(payload) {
      try {
        const grpCd = payload.grpCd;
        this.sermonPayload.grpCd = grpCd;

        if (isEmpty(this.sermonList) || this.sermonList.length === 0) {
          this.sermonPayload.startNum = 0;
          // this.sermonPaging.lastIdx = 0;
          this.sermonPaging.isEnd = false;
        }

        if (this.sermonPaging.isEnd === false) {
          if (!isEmpty(this.sermonList) && this.sermonList.length > 0) this.sermonPayload.startNum += 10;

          const { statusText, data: res = {} } = await getMyGroupSermonListAPI(this.sermonPayload);
          if (statusText !== "OK" || res?.result === undefined) throw Error;

          if (res.result.length < 10) this.sermonPaging.isEnd = true;

          if(this.sermonPayload.startNum == 0) this.sermonGrpName = res.grpName;

          if (res.result.length) {
            let videoList = [];

            // 가장 최근 데이터의 mediaType에 따라 분기
            if (res.result[0]?.mediaType == "channel") {
              this.youtubeChannelVideoList.some((itm) => {
                if(itm.grpCd == grpCd) {
                  videoList = itm.data;
                  return true;
                }
              });
            } else {
              // 개별 입력한 동영상 목록
              for(const itm of res.result) {
                if (itm.mediaType == "video") {
                  itm.video = await getVideoInfo(itm.videoLink);

                  videoList.push(itm);
                }
              }
            }

            this.sermonList = [...this.sermonList, ...videoList];
          }
        }
      } catch (err) {
        console.error(`getMyGroupSermonList err : `, err);
      }
    },
    async checkGroupIdActor(payload) {
      try {
        const { data: res = {} } = await checkGroupIdAPI(payload);
        if (res?.result === undefined) throw Error;
        console.log("checkGroupIdActor", res);
        return res.result;
      } catch (err) {
        console.error(`checkGroupIdActor err : `, err);
      }
    },
    async registGroupInfoActor(payload) {
      try {
        const { data: res = {} } = await registGroupInfoAPI(payload);
        if (res?.result === undefined) throw Error;
        console.log("registGroupInfoActor", res);
        return res.result;
      } catch (err) {
        console.error(`registGroupInfoActor err : `, err);
      }
    },

    // 내 교회/단체 대시보드 정보(메인 대시보드)
    async getMyGroupDashboard() {
      try {
        const { statusText, data: res = {} } = await getMyGroupDashboardAPI();
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        const dashboardList = [];

        // await시 for of
        for (const itm of res.result) {
          itm.info.grpMngrYn = itm.info.grpMngrYn == "Y";

          let videoLink = itm.sermon.videoLink;

          if (itm.sermon.mediaType == "channel") {
            /**
             * @title : 채널일 경우
             * sermonData가 있을 경우
             * - 쿠키 존재하면 Youtube Data API 패스 => sermonData 적용
             * - 쿠키 없으면 delete DB(grpCd) => Youtube Data API 호출 => channelVideoList 적용, 쿠키 저장, DB 저장
             * sermonData가 없을 경우
             * - Youtube Data API 호출 => channelVideoList 적용, 쿠키 저장, DB 저장
             */

            const grpCd = itm.info.grpCd;
            let sermonData = itm.sermon.sermonData;
            let channelVideoList = [];

            const getVideoListActor = async () => {
              channelVideoList = await getYoutubeChannelVideoList({
                videoUrl: videoLink,
                maxResults: 10,
              });

              if(channelVideoList.length) {
                // 쿠키 저장
                youtubeChannelDataAPICookieCheck(grpCd);

                // DB 저장
                await this.saveYoutubeChannelVideoList({
                  grpCd,
                  sermonData: JSON.stringify(channelVideoList)
                });
              }
            }

            if(isEmpty(sermonData)) {
              await getVideoListActor();
            } else {
              if(youtubeChannelDataAPICookieCheck(grpCd)) {
                // 쿠키 존재하면 api 패스 => sermonData
                channelVideoList = sermonData;
              } else {
                // 쿠키 없으면 delete DB => api 호출 => videoList, 쿠키 저장, DB 저장
                await this.deleteYoutubeChannelVideoList({
                  grpCd
                });

                await getVideoListActor();
              }
            }

            if(channelVideoList.length) {
              // 설교목록용
              const hasCd = this.youtubeChannelVideoList.some((itm) => itm.grpCd == grpCd);

              if(!hasCd) {
                this.youtubeChannelVideoList.push({
                  grpCd: grpCd,
                  data: _.cloneDeep(channelVideoList)
                });
              }

              // console.log("youtubeChannelVideoList =====> ", this.youtubeChannelVideoList);

              /**
               * 대시보드용 : 채널 내 최근 1개
               */
              const channelVideo = channelVideoList[0];

              // thumb
              if(!isEmpty(channelVideo.video.thumb)) {
                const thumb = channelVideo.video.thumb;
                if(thumb.indexOf("/maxresdefault")) thumb
                channelVideo.video.thumb = channelVideo.video.thumb.replace("/maxresdefault", "/mqdefault");
              }

              // 7일 이내 여부
              const sermonDate = new Date(channelVideo.sermonDate);
              const todayDate = new Date();
              channelVideo.up = DateDiff(sermonDate, todayDate) < 3;

              itm.sermon = { ...channelVideo };
            } else {
              // 채널 내 영상 없을 경우, Youtube Data API quotaExceeded Error
              itm.sermon = null;
            }
          } else {
            // 개별일 경우
            itm.sermon.video = await getVideoInfo(videoLink);
          }

          dashboardList.push(itm);
        }

        this.dashboardList = dashboardList;
      } catch (err) {
        console.error(`getMyGroupDashboard err : `, err);
      }
    },

    // dashboard update(메인 대시보드 노출/비노출)
    async updateMyGroupDashboard(payload) {
      try {
        const { statusText, data: res = {} } = await updateMyGroupDashboardAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateMyGroupDashboard err : `, err);
      }
    },

    // 내 교회/단체 목록
    async getMyGroupList() {
      try {
        const { statusText, data: res = {} } = await getMyGroupListAPI();
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        this.myGroupList = res.result;
      } catch (err) {
        console.error(`getMyGroupList err : `, err);
      }
    },

    // my group update(내 교회/단체 목록 순서 저장)
    async updateMyGroupList(payload) {
      try {
        const { statusText, data: res = {} } = await updateMyGroupListAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateMyGroupList err : `, err);
      }
    },

    // my group 취소(교회/단체 멤버 탈퇴)
    async cancelMyGroupList(payload) {
      try {
        const { statusText, data: res = {} } = await cancelMyGroupListAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`cancelMyGroupList err : `, err);
      }
    },

    // 대시보드 : 공동체 취소
    async cancelMyGroupAction(payload) {
      try {
        if(!confirm(`[${payload.grpName}] 공동체를 탈퇴하시겠습니까?\n\n※ 탈퇴시 공동체에 소속된 나눔방도 함께 탈퇴 처리됩니다.`)) return false;

        let cookieSiteLang = useCookie("dwjSiteLang", {
          domain: ".jwj.kr",
          path: "/",
        });

        const todayDate = dayjs(new Date()).format("YYYYMMDDhhmmss");

        const grpCd = payload.grpCd;  // 그룹코드
        const memCd = payload.memCd;   // 회원코드
        const siteLangCd = cookieSiteLang.value;  // 언어코드
        const selfQuitYn = "Y";   // 본인탈퇴여부
        const quitRsn = "공동체 탈퇴";  // 탈퇴사유
        const joinEdDate = todayDate;    // 가입종료일시
        const grpMemEdDate = todayDate;  // 그룹회원종료일시

        return await this.cancelMyGroupList({
          grpCd,
          memCd,
          siteLangCd,
          selfQuitYn,
          quitRsn,
          joinEdDate,
          grpMemEdDate
        });
      } catch (err) {
        console.error(`cancelMyGroupAction err : `, err);
      }
    },

    async setOrderModal(boolean) {
      this.orderModalOpen = boolean;
      this.orderModalClose = !boolean;

      if (boolean) {
        await this.getMyGroupList();
      } else {
        this.myGroupList = [];
      }

      return boolean;
    },

    async setDrag(boolean) {
      this.drag = boolean;

      if (this.drag === false) {
        // 순서 저장
        const payload = {
          grpCd: [],
        };

        this.myGroupList.map((itm) => {
          payload.grpCd.push(itm.grpCd);
        });

        payload.grpCd = payload.grpCd.toString();

        this.updateMyGroupList(payload);
      }

      return boolean;
    },

    // 동역
    async getFellowList() {
      try {
        const { statusText, data: res = {} } = await getFellowListAPI();
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`getFellowList err : `, err);
      }
    },

    // 동역 상세
    async getFellowDetail(payload) {
      try {
        const { statusText, data: res = {} } = await getFellowDetailAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;
        return res.result;
      } catch (err) {
        console.error(`getFellowDetail err : `, err);
      }
    },

    // 회원탈퇴
    async cancelMembership(payload) {
      try {
        const { statusText, data: res = {} } = await cancelMembershipAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;
        return res.result;
      } catch (err) {
        console.error(`cancelMembership err : `, err);
      }
    },

    // 메인 moreLinks : 동행훈련(365) 링크 파라미터 조회
    async get365AuthKey(payload) {
      try {
        const { data: res = {} } = await get365AuthKeyAPI(payload);
        if (res === undefined) throw Error;
        return res.param;
      } catch (err) {
        console.error(`get365AuthKey err : `, err);
      }
    },

    // save youtube channel/playlist video list
    // payload: {grpCd, sermonData: videoList}
    async saveYoutubeChannelVideoList(payload) {
      try {
        const { data: res = {} } = await saveYoutubeChannelVideoListAPI(payload);
        if (res === undefined) throw Error;
      } catch (err) {
        console.error(`saveYoutubeChannelVideoList err : `, err);
      }
    },

    // delete youtube channel/playlist video list
    // payload: {grpCd}
    async deleteYoutubeChannelVideoList(payload) {
      try {
        const { data: res = {} } = await deleteYoutubeChannelVideoListAPI(payload);
        if (res === undefined) throw Error;
      } catch (err) {
        console.error(`deleteYoutubeChannelVideoList err : `, err);
      }
    }

  },
});

function parseVideo(url) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  // RegExp.$1 : https:
  // RegExp.$2 : www.
  // RegExp.$3 : youtube.com
  // RegExp.$4 : be.com
  // RegExp.$5 : watch?v=
  // RegExp.$6 : My2FRPA3Gf8 || channel
/*
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|channel\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );
 */
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|channel\/|playlist\?list=|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );

  var vtype = RegExp.$3,
    vid = RegExp.$6,
    isChannel = false,
    isPlaylist = false;

  if (vtype.indexOf("youtu") > -1) {
    vtype = "youtube";
    if (vid.length > 11) {
      const flag = vid.substring(0, 2).toUpperCase();
      if(flag == "UC") isChannel = true;
      else if(flag == "PL") isPlaylist = true;
    }
  } else if (vtype.indexOf("vimeo") > -1) {
    vtype = "vimeo";
  } else {
    vtype = "";
  }

  return {
    type: vtype,
    id: vid,
    isChannel: isChannel,
    isPlaylist: isPlaylist,
  };
}

// 이미지 존재 여부
function isLoadableImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}

// video info
async function getVideoInfo(videoUrl) {
  const videoInfo = {
    url: "",
    thumb: "",
    type: "",
  },
  getYoutubeThumb = (videoId, thumbSize = 'maxresdefault') => {
    const thumbUrl = `https://img.youtube.com/vi/${videoId}/${thumbSize}.jpg`;

    if(isLoadableImage(thumbUrl)) return thumbUrl;

    if(thumbSize == 'maxresdefault') getYoutubeThumb(videoId, "sddefault");
    else if(thumbSize == 'sddefault') getYoutubeThumb(videoId, "hqdefault");
    else if(thumbSize == 'hqdefault') getYoutubeThumb(videoId, "mqdefault");
    else if(thumbSize == 'mqdefault') getYoutubeThumb(videoId, "default");
  }

  if(isEmpty(videoUrl)) return videoInfo;

  let video = parseVideo(videoUrl);

  if(video.type == "youtube") {
    videoInfo.url = "https://www.youtube.com/embed/" + video.id;
    videoInfo.thumb = getYoutubeThumb(video.id);
  } else if(video.type == "vimeo") {
    try {
      const { data: res = {} } = await getVimeoVideoInfoAPI(videoUrl);
      if (res === undefined) throw Error;

      // console.log("vimeo res", res);
      videoInfo.url = "https://player.vimeo.com/video/" + res.video_id;
      videoInfo.thumb = res.thumbnail_url;
    } catch (err) {
      console.log("getVideoInfo - vimeo err", err);
    }
  }

  videoInfo.type = video.type;

  return videoInfo;
}

// youtube channel video list
async function getYoutubeChannelVideoList(obj) {
  const videoList = [];
  const videoUrl = obj.videoUrl;

  const video = parseVideo(videoUrl);

  if(!video.isChannel && !video.isPlaylist) return videoList;

  const payload = {
    id: video.id,
    maxResults: !isEmpty(obj.maxResults) ? obj.maxResults : 20,
    isChannel: video.isChannel,
    isPlaylist: video.isPlaylist
  }

  try {
    const { data: res = {} } = await getYoutubeChannelVideoListAPI(payload);
    if (res?.items === undefined) throw Error;

    // console.log("getYoutubeChannelVideoListAPI res.items =====>", res.items);

    res.items.forEach((el, index) => {
      const snippet = el.snippet;
      const videoId = video.isChannel ? el.id.videoId : snippet.resourceId.videoId;
      const publishDate = video.isChannel ? snippet.publishTime : snippet.publishedAt;

      // console.log("snippet==>", snippet);

      let thumb = snippet.thumbnails.maxres?.url;
      if(isEmpty(thumb)) thumb = snippet.thumbnails.standard?.url;
      if(isEmpty(thumb)) thumb = snippet.thumbnails.high?.url;
      if(isEmpty(thumb)) thumb = snippet.thumbnails.medium?.url;
      if(isEmpty(thumb)) thumb = snippet.thumbnails.default?.url;

      // console.log("thumb==>", thumb);

      videoList.push({
        channelUrl: video.isChannel || video.isPlaylist ? videoUrl : "",    // 'Youtube에서 더보기'를 위해 추가
        bible: "",
        sermonCd: el.etag,
        preacher: snippet.channelTitle,
        sermonDate: dayjs(publishDate).format("YYYY-MM-DD"),
        mediaType: "channel",
        title: snippet.title,
        sermonType: "",
        videoLink: "https://www.youtube.com/watch?v=" + videoId,
        video: {
          url: "https://www.youtube.com/embed/" + videoId,
          // thumb: video.isChannel ? snippet.thumbnails.high.url : snippet.thumbnails.standard.url,
          thumb,   // default: 120*90, medium: 320*180, high: 480*360, standard: 640*480, maxres: 1280*720
          type: "youtube",
        },
      });
    });
  } catch (err) {
    console.error(`getYoutubeChannelVideoList err : `, err);
  }

  return videoList;

  /*
  {
    "kind": "youtube#searchResult",
    "etag": "XWnEVCiFvkZlSeJzhPbXABf-TrM",
    "id": {
        "kind": "youtube#video",
        "videoId": "ZkF0sKqjYxM"
    },
    "snippet": {
        "publishedAt": "2024-09-19T12:36:35Z",
        "channelId": "UCIyvPLWipoYrkutcssIoJnA",
        "title": "청년의 기도",
        "description": "악보는 블로그에 있습니다. https://blog.naver.com/kyungmin0716/222210298268 노래 - 손경민, 김정희 작사.곡 - 손경민 영상 ...",
        "thumbnails": {
            "default": {
                "url": "https://i.ytimg.com/vi/ZkF0sKqjYxM/default.jpg",
                "width": 120,
                "height": 90
            },
            "medium": {
                "url": "https://i.ytimg.com/vi/ZkF0sKqjYxM/mqdefault.jpg",
                "width": 320,
                "height": 180
            },
            "high": {
                "url": "https://i.ytimg.com/vi/ZkF0sKqjYxM/hqdefault.jpg",
                "width": 480,
                "height": 360
            }
        },
        "channelTitle": "손경민 (Son Kyungmin Official)",
        "liveBroadcastContent": "none",
        "publishTime": "2024-09-19T12:36:35Z"
    }
  }
 */
}


// 그룹별 youtube channel/playlist api 요청 1일 1회 제한용
function youtubeChannelDataAPICookieCheck(grpCd) {
  const date = new Date();
  const expireTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)); // 오늘 23:59:59 만료
  // const expireTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1, 17, 59, 59)); // 내일 17:59:59 만료

  let cookie = useCookie("ytc_dat", {
    domain: ".jwj.kr",
    path: "/",
    expires: expireTime,
  });

  let cookieValue = String(cookie.value);

  if (!isEmpty(cookieValue)) {
    if(cookieValue.indexOf(",") > -1 && cookieValue.includes(grpCd) || cookieValue === String(grpCd)) {
      return true;
    } else {
      cookie.value += ", " + grpCd;
      return false;
    }
  } else {
    cookie.value = grpCd;
    return false;
  }
}


// 날짜 차이
function DateDiff(startDate, endDate) {
  return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
}

