import axios from "axios";
import { objToFormData } from "~/utils/utils";

// /ajax/util/selectMyGroupDashboard.do

const axiosInstance = function (url) {
  const hostname = window.location.hostname;
  let baseURL = url || "/api";

  return axios.create({
    baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * @title [033] 내 알림 카운트 API
 */
export const getAlarmNotiCountAPI = async () => {
  try {
    return await axiosInstance("/ajax").get("/util/selectAllGetCount.do");
  } catch (err) {
    console.error(`getAlarmNotiCountAPI err : `, err);
    throw err;
  }
};

/**
 * @title [022] 알림 목록 API
 * @startNum : 0
 * @endNum : 1
 * @mssgChkYn : 메시지 상태, N
 */
export const getAlarmListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectAlarmList.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getAlarmListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [022-01] 알림 상세 API
 * @notiMssgCd : 알림메시지코드
 */
export const getAlarmDetailInfoAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectAlarmInfoDet.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getAlarmDetailInfoAPI err : `, err);
    throw err;
  }
};

/**
 * @title [085] 알림 메시지 저장 API(승인/거절)
 */
export const insertAlarmProcAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/insertAlarmProc.do", objToFormData(payload));
  } catch (err) {
    console.error(`insertAlarmProcAPI err : `, err);
    throw err;
  }
};

/**
 * @title [085-01] 알림 메시지 읽은 후 읽음처리 API
 * @notiMssgCd : 알림메시지코드
 * @mssgChkYn : 메시지읽음여부:Y
 */
export const updateAlarmInfoAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/updateAlarmProc.do", objToFormData(payload));
  } catch (err) {
    console.error(`updateAlarmInfoAPI err : `, err);
    throw err;
  }
};

/**
 * @title [101] 공지사항 목록 API
 */
export const getNoticeListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectNoticeList.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getNoticeListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [101-1] 공지사항 읽음 저장 API
 */
export const updateNoticeReadAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/updatePostProc.do", objToFormData(payload));
  } catch (err) {
    console.error(`updateNoticeReadAPI err : `, err);
    throw err;
  }
};

/**
 * @title [003] 배너 목록 API
 * */
export const getBannerListAPI = async () => {
  try {
    return await axiosInstance().get(`/util/selectBannerList.do`);
  } catch (err) {
    console.error(`getDailyListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [107] 설정 정보 API
 */
export const getSettingInfoAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectSettingInfo.do`);
  } catch (err) {
    console.error(`getUserSettingAPI err : `, err);
    throw err;
  }
};

/**
 * @title [108] 설정 정보 저장 API
 * @memCd : 회원코드
 * @siteAlarmYn : 일기작성시간 알람설정 여부(Y/N)
 * @alarmDate : 일기작성시간(hhmm)
 * @pushAlarmRcv : 위지엠 공지 알람 여부(Y/N)
 */
export const updateSettingInfoAPI = async (payload) => {
  return axiosInstance("/ajax").post(`/usr/updateUserProc.do`, objToFormData(payload));
};

/**
 * @title [001] 업로드 아바타 API
 */
export const uploadAvatarImageAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/usr/updateUserProfileProc.do", objToFormData({ ...payload }));
};

/**
 * @title [001] 다국어 목록 API
 */
export const getLanguageListAPI = async () => {
  try {
    return await axiosInstance("").post(`/common/selectLanguageList.do`);
  } catch (err) {
    console.error(`getLanguageListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [075] 내 나눔방 목록 API
 */
export const getMyRoomListAPI = async () => {
  return axiosInstance("/ajax").get(`/room/selectMyRoomMainList.do`);
};

/**
 * @title [110] 나눔방 전체 알람 설정 저장 API
 */
export const updateSettingRoomAlarmAllAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/updateRoomAlarmAll.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateSettingRoomAlarmAllAPI err : `, err);
    throw err;
  }
};

/**
 * @title [111] 언어 설정 저장 API
 */
export const updateSettingLanguageAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/usr/updateUserLangProc.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateSettingLanguageAPI err : `, err);
    throw err;
  }
};

/**
 * @title [104] 온라인헌금 계좌 API
 */
export const getMyGroupAccountAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupAccount.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getMyGroupAccountAPI err : `, err);
    throw err;
  }
};

/**
 * @title [100] 내 교회/단체 대시보드 API
 */
export const getMyGroupDashboardAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupDashboard.do`);
  } catch (err) {
    console.error(`getMyGroupDashboardAPI err : `, err);
    throw err;
  }
};

/**
 * @title [097-1] 내 교회/단체 대시보드 설정 API
 */
export const updateMyGroupDashboardAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/grp/updateGrpMemDisplay.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateMyGroupDashboardAPI err : `, err);
    throw err;
  }
};

/**
 * @title [096] 내 교회/단체 목록 API
 */
export const getMyGroupListAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/grp/selectMyGrpList.do`);
  } catch (err) {
    console.error(`getMyGroupListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [097-1] 내 교회/단체 대시보드 설정 API
 */
export const updateMyGroupListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/grp/saveGrpOrder.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateMyGroupListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [093-1] 교회/단체 멤버 탈퇴 API
 */
export const cancelMyGroupListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/grp/withdraw.do", objToFormData(payload));
  } catch (err) {
    console.error(`cancelMyGroupListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [103] 주보 API
 */
export const getMyGroupJuboListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupJuboList.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getMyGroupJuboListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [102] 설교 영상 API
 */
export const getMyGroupSermonListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupSermonList.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getMyGroupSermonListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [114] 동역 목록 API
 */
export const getFellowListAPI = async () => {
  try {
    return await axiosInstance("/ajax").get("/util/selectFellowList.do");
  } catch (err) {
    console.error(`getFellowListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [115] 동역 상세 API
 */
export const getFellowDetailAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectFellowDet.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getFellowDetailAPI err : `, err);
    throw err;
  }
};

/**
 * @title [106] 회원 탈퇴 API
 */
export const cancelMembershipAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/usr/updateMemberWithDraw.do", objToFormData(payload));
  } catch (err) {
    console.error(`cancelMembershipAPI err : `, err);
    throw err;
  }
};

/**
 * [094-01] 교회/단체 등록 API
 * @param {string} grpName : 그룹(교회/단체) 이름
 * @param {string} belongCity : 도시이름
 * @param {string} bldAskLDate : 개설신청로컬일시 (yyyyMMddHH:mm:ss)
 * @param {string} grpTypeCd : 그룹유형코드 교회: GrpType0010000000001, 단체: GrpType0010000000003
 * @param {string} siteLangCd : 언어코드
 * @param {string} nationCd : 국가코드
 * @param {string} ldrName : 대표자 이름
 * @param {string} grpContact : 대표자 연락처
 * @param {string} grpMngrYn : 그룹관리여부 Y
 * @param {string} chrchCd : 교회코드(교회일 경우)
 * @param {string} privacyPolishCovenantChkYn : 보안서약서 동의 여부
 * @param {string} chrgPastorCerti : 교회/단체 증명파일코드(=uploadFileCd)
 * @param {string} grpId : 그룹(교회/단체) 아이디
 * @param {string} applicantName
 * @return {Promise} result : 1성공, 0실패
 */
export const registGroupInfoAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/makeGroupInfoProc.do", objToFormData(payload));
};

/**
 * [094-02] 교회/단체 아이디 중복여부 조회 API
 * @param {string} grpId : 그룹(교회/단체) 아이디
 * @returns {Promise} result : 0보다 크면 중복
 */
export const checkGroupIdAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/selectGroupIdDup.do", { params: { ...payload } });
};

/**
 * @title [117-04] 설교 Youtube DATA 저장 API
 * Youtube Channel/Playlist Data 저장
 */
export const saveYoutubeChannelVideoListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/grp/sermon/data.do", objToFormData(payload));
  } catch (err) {
    console.error(`saveYoutubeChannelDataAPI err : `, err);
    throw err;
  }
};

/**
 * @title [117-05] 설교 Youtube DATA 초기화 API
 * Youtube Channel/Playlist Data 초기화
 */
export const deleteYoutubeChannelVideoListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/grp/sermon/data-delete.do", objToFormData(payload));
  } catch (err) {
    console.error(`saveYoutubeChannelDataAPI err : `, err);
    throw err;
  }
};

/**
 * @title : Youtube 채널내 동영상 검색 API
 */
export const getYoutubeChannelVideoListAPI = async (payload) => {
  let params = {
    // key: "AIzaSyB5AnD-Kv6FRCtUEMdMfnz_H-y9GdVASXE",
    key: "AIzaSyCaMlmEb6f6vf9FmldlSN2IuBkMbwcDJTo",
    part: "snippet",
    maxResults: payload.maxResults, // 최대 개수
  };

  if (payload.isChannel) {
    params = {
      ...params,
      channelId: payload.id,
      type: "video",
      order: "date", // 등록날짜순 정렬
      // videoSyndicated: "true",  // 외부에서 재생가능한 동영상만 검색
    };
  } else {
    params = {
      ...params,
      playlistId: payload.id,
    };
  }

  /*   if(payload.isChannel) return {items: []};

  params = {
    ...params,
    playlistId: payload.id,
  }
 */
  const baseUrl = "https://www.googleapis.com/youtube/v3";
  const url = payload.isChannel ? baseUrl + "/search" : baseUrl + "/playlistItems";

  return await axios.get(url, { params: params });
};

/**
 * @title : Vimeo 동영상 정보 조회 API
 */
export const getVimeoVideoInfoAPI = async (url) => {
  return await axios.get("https://vimeo.com/api/oembed.json", { params: { url } });
};

/**
 * @title : [122] 동행훈련(365) 사이트 링크 API
 */
export const get365AuthKeyAPI = async (url) => {
  return await axiosInstance("/ajax").get("/util/select365AuthKey.do");
};
